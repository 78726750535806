// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-article-index-js": () => import("./../../../src/containers/Article/index.js" /* webpackChunkName: "component---src-containers-article-index-js" */),
  "component---src-containers-member-index-js": () => import("./../../../src/containers/Member/index.js" /* webpackChunkName: "component---src-containers-member-index-js" */),
  "component---src-containers-vacancy-index-js": () => import("./../../../src/containers/Vacancy/index.js" /* webpackChunkName: "component---src-containers-vacancy-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asean-js": () => import("./../../../src/pages/asean.js" /* webpackChunkName: "component---src-pages-asean-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-korean-js": () => import("./../../../src/pages/korean.js" /* webpackChunkName: "component---src-pages-korean-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-practice-js": () => import("./../../../src/pages/practice.js" /* webpackChunkName: "component---src-pages-practice-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

